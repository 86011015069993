<template>
  <div class="worker-jobs">
    <Navbar />
    <Breadcrumbs></Breadcrumbs>
    <WorkerJobs/>
    <Footer />
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Navbar from '@/components/Navbar.vue'
import WorkerJobs from '../components/worker-jobs/WorkerJobs.vue'
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    WorkerJobs,
    Footer
  }
}
</script>

<style scoped>
.cont {
  min-height: 73vh ;
}

</style>
