<template>
    <button class="list-group-item flex-column align-items-start" :class="listItemClass[workObject.work_status]"
        @click="$emit('click:item', workObject)">
        <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
                <span class="me-2">
                    {{ getPositionFullindex(workObject.task_obj.work_position_obj) }}
                </span>
                <span>
                    {{ workObject.task_obj.work_position_obj.name }}
                </span>
            </h5>
            <small>{{ status[workObject.work_status] }}</small>
        </div>
        <div class="location">
            <span class="me-2">
                {{ getLocationFullindex(workObject.task_obj.work_location_obj) }}
            </span>
            <span v-for="(name, index) in fullNameList(workObject.task_obj.work_location_obj)" :key="index">
                {{ name }} &gt;
            </span>
            <span>
                {{ workObject.task_obj.work_location_obj.name }}
            </span>
        </div>
    </button>
</template>

<script>

import UtilService from '@/service/UtilService.js';

export default {

    emits: ['click:item'],

    props: {
        workObject: Object
    },

    data() {
        return {
            status: {
                ASSIGNED: "DODELJEN",
                IN_PROGRESS: "U TOKU",
                SUBMITTED: "PREDATO",
                REVIEWED: "PREGLEDANO",
                NOT_STARTED: "NIJE POČELO"
            },
            listItemClass: {
                ASSIGNED: "list-group-item-light",
                IN_PROGRESS: "list-group-item-primary",
                SUBMITTED: "list-group-item-warning",
                REVIEWED: "list-group-item-success",
                NOT_STARTED: "list-group-item-secondary"
            }
        }
    },

    methods: {
        getLocationFullindex: UtilService.getLocationFullindex,
        getPositionFullindex: UtilService.getPositionFullindex,
        fullNameList: UtilService.fullNameList,
    }
}

</script>

<style scoped>
.location {
    text-align: initial;
}
</style>