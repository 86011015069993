<template>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                :data-bs-target="'#collapse-job-' + order" aria-expanded="false" :aria-controls="'collapse-job-' + order">
                # {{ order+1 }}
            </button>
        </h2>
        <div :id="'collapse-job-' + order" class="accordion-collapse collapse show" aria-labelledby="headingOne">
            <div class="accordion-body">
                <div class="list-group gap">
                    <h5 v-for="worker in assignment.employees" :key="worker.id">{{ worker.first_name }} {{ worker.last_name }}</h5>
                    <h5>Menadzer: {{ assignment.manager.first_name }} {{ assignment.manager.last_name }}</h5>
                    <job-view-item v-for="(wo, index) in assignment.works_obj" :key="index" :workObject="wo"
                        @click:item="openModal(wo)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import JobViewItem from './JobViewItem.vue';

export default {
    components: { JobViewItem },

    emits: ['click:item'],

    props: ['assignment', 'order'],

    methods: {
        openModal(event) {
            event.jobAssignmentId = this.assignment.id
            this.$emit('click:item', event)
        }
    }
}

</script>

<style scoped>
.gap {
    gap: 0.4em;
}

.list-group-item {
    border-radius: 0.5em;
}
</style>