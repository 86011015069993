<template>

        <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content" v-if="positionUnit">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                        Radna Pozicija
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <h5>Naziv</h5>
                        <p>{{positionUnit.name}}</p>

                        <h5>Minuta po jedinici</h5>
                        <p>{{positionUnit.minutes_per_unit}} min</p>

                        <h5>Jedinica</h5>
                        <p>{{positionUnit.norm_quantity}} {{positionUnit.norm_unit}}</p>

                        <h5>Opis</h5>
                        <p>{{positionUnit.description}}</p>

                        <template v-if="positionUnit.video_link">
                            <h5>Video
                                <a v-if="positionUnit.video_link" :href="positionUnit.video_link" target="_blank"><i class="fas fa-play fa-lg me-1" /></a>
                            </h5>
                            <iframe width="100%" height="500px" :src="getEmbededLink(positionUnit.video_link)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </template>
                        
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

export default {

    props: {
        positionUnit: Object,
        inputId: {
            type: String,
            default: "",
        }
    },

    data: function() {
        return {
            workOrderForm: {}
        }
    },

    created() {
        if (this.workOrder) {
            this.workOrderForm.id = this.workOrder.id;
            this.workOrderForm.name = this.workOrder.name;
            this.workOrderForm.code = this.workOrder.code;
            this.workOrderForm.investor = this.workOrder.investor;
            this.workOrderForm.start_date = this.workOrder.start_date;
            this.workOrderForm.end_date = this.workOrder.end_date;
            this.workOrderForm.real_end_date = this.workOrder.real_end_date;
            this.workOrderForm.agreed_value = this.workOrder.agreed_value;
        }
    },

    methods: {
        getId(url) {
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);

            return (match && match[2].length === 11)
            ? match[2]
            : null;
        },

        getEmbededLink(url) {
            return '//www.youtube.com/embed/' + this.getId(url)
        },

        createWorkOrder() {
            let form = document.getElementById("new-work-order-form");
            
            form.classList.add('was-validated');
            if (!form.checkValidity())
                return;

            if (this.workOrder) {
                this.$store.dispatch('updateWorkOrder', this.workOrderForm)
                .then(() => {
                    alert("Radni nalog je uspešno izmenjen.");  
                    this.$router.push({ path: '/radni-nalozi' });
                })
                .catch((error) => alert(error.message))
            }
            else {
                this.$store.dispatch('createWorkOrder', this.workOrderForm)
                .then(() => {
                    this.$store.dispatch('loadAllWorkOrders', this.workOrderForm);
                    alert("Radni nalog je uspešno dodat.");
                })
                .catch((error) => alert(error.message));    
            }

            this.$emit('confirm');
        }
    },

}
</script>

<style>

</style>