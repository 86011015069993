<template>


<div class="container">
    <!-- <ModalWorkerJob v-model="selectedJob" inputId="worker-jobs-modal" /> -->
    <ModalPositionUnitInfo :positionUnit="selectedModalPositionUnit" inputId="position-unit-modal" />

    <div class="mb-3 d-flex w-100 justify-content-between">
        <h3>Profil: {{user.username}}</h3>
        <button class="btn btn-primary" v-on:click="loadJobAssignments"><font-awesome-icon icon="sync" /></button>
    </div>

    <task-work-details-modal ref="JobViewList" :taskWork="selectedTaskWork" @onConfirm="taskWorkModalConfirmed" />
    <div class="accordion">
        <job-view-list v-for="assignment, index in jobAssignments" :key="index" :assignment="assignment" :order="index"
            @click:item="$event => onItemClicked($event)" />
    </div>

    <h6 v-if="workerJobsSorted && workerJobsSorted.length == 0">Nema zadatih pozicija</h6>

</div>
</template>

<script>
import {mapState} from 'vuex'
import UtilService from '@/service/UtilService.js';
// import ModalWorkerJob from '@/components/modals/ModalWorkerJob.vue';
import TaskWorkDetailsModal from './modals/TaskWorkDetailsModal.vue';
import JobViewList from './JobViewList.vue';
import ModalPositionUnitInfo from '@/components/modals/ModalPositionUnitInfo.vue';
import JobAssignmentService from '@/service/JobAssignmentService.js';

export default {
    components: { ModalPositionUnitInfo, TaskWorkDetailsModal, JobViewList },

    data() {
        return {
            userId: 2,
            selectedTaskWork: null,
            jobAssignments: [],
            selectedModalPositionUnit: null,
        }
    },
    watch: {
        'worksInJobStatus': function() {
            if (this.worksInJobStatus == "SUCCESS")
                this.loadJobAssignments();
        },
        // 'workerJobsStatus': function() {
        //     if (this.workerJobsStatus) {
        //         this.$store.dispatch('resetWorkerJobsStatus');
        //         this.loadJobAssignments();
        //     }
        // }
    },
    computed: {
        ...mapState([
            'user',
            'workerJobs',
            'worksInJobStatus',
            'workerJobsStatus',
            'position_units',
        ]),


        workerJobsSorted(){
            if (!this.workerJobs) return [];

            let jobs = UtilService.deepClone(this.workerJobs);
            jobs.sort((a,b) => (a.started_date_time > b.started_date_time ? 1 : -1 ));
            jobs.forEach(job => {
                if (!job.works) return 0;
                job.works.sort((a,b) => (a.id < b.id ? 1 : -1 ));
            });
            return jobs;
        }
    },

    methods: {
        formatDate : UtilService.formatDate,
        formatTime : UtilService.formatTime,
        formatDateTime : UtilService.formatDateTime,

        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,

        // getWorkLabel(work) {
        //     if (!work) return "?"
        //     let work_status = work.work_status

        //     if (work_status == "ASSIGNED") return "Spremno"
        //     if (work_status == "IN_PROGRESS") return "U toku"
        //     if (work_status == "SUBMITTED") return "Zavrseno"
        //     if (work_status == "REVIEWED") return "Pregledano"
        //     if (work_status == "NOT_STARTED") return "Nije započeto"
        // },

        // openJobsModal(job) {
        //     this.$store.dispatch('resetJobStatus');
        //     job.edited = false;
        //     this.selectedJob = UtilService.deepClone(job); 
        // },

        // isChecked(work){
        //     return work.workStatus == 'DONE'
        // },

        // isNotStarted(work){
        //     return work.workStatus == 'NOT_DONE'
        // },

        // openPositionUnitModal(job) {
        //     this.selectedModalPositionUnit = job.work_position.position_unit;
        // },

        // isJobCompled(job) {
        //     let not_finished;
        //     not_finished = job.works?.some(function(work) {
        //         return !UtilService.isWorkFinishedUser(work);
        //     });
            
        //     return !not_finished;
        // },

        // finishJob(job) {
        //     this.$store.dispatch('submitJobForReview', {jobAssignmentId: job.id}).then(() => {
        //         this.loadJobAssignments();
        //     });
        // },

        // getPositionUnit(workPositionId) {
        //     return this.position_units[workPositionId];
        // },

        // startWork(work) {
        //     console.log(work);
        //     work.task_status = "IN_PROGRESS"

        // },

        // finishWork(work) {
        //     console.log(work);
        //     work.task_status = "SUBMITTED"
        // },


        taskWorkModalConfirmed() {
            this.loadJobAssignments();
        },

        onItemClicked($event) {
            this.selectedTaskWork = $event
            this.$refs.JobViewList.showModal();
        },

        async loadJobAssignments() {
            let payload = {
                url: {
                    employeeId: this.user.id
                }
            }

            try {
                let response = await JobAssignmentService.getJobAssignments(payload) 
                UtilService.prepareWorkerJobs(response.data);
                this.jobAssignments = response.data
            } catch(error) {
                console.log(error);
            }

        }

    },

    async created() {
        // await this.$store.dispatch('loadPositionUnitsIfUndefined');
        await this.loadJobAssignments();
    },

}
</script>

<style scoped>
    .bg-checked {
        background: rgb(189 255 189);
    }
    
    .bg-not-started {
        background: rgb(255 220 220);
    }

    .job-info-list {
        text-decoration: none;
        list-style-type: none;
    }
</style>