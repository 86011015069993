<template>
    <div class="modal fade" tabindex="-1">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Detalji</h5>
                    <button type="button" class="btn-close" @click="cancelModal()" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid" v-if="taskWork">
                        <div>
                            <div class="col-12">
                                <div class="card" v-if="taskWork && taskWork.task_obj">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <h5 class="card-title">
                                                <span class="me-2">
                                                    {{ getPositionFullindex(taskWork.task_obj.work_position_obj) }}
                                                </span>
                                                <span>
                                                    {{ taskWork.task_obj.work_position_obj.name }}
                                                </span>
                                            </h5>
                                            <small>{{ status[taskWork.work_status] }}</small>
                                        </div>
                                        <p class="card-text">
                                            <span class="me-2">
                                                {{ getLocationFullindex(taskWork.task_obj.work_location_obj) }}
                                            </span>
                                            <span v-for="(name, index) in fullNameList(taskWork.task_obj.work_location_obj)"
                                                :key="index">
                                                {{ name }} &gt;
                                            </span>
                                            <span>
                                                {{ taskWork.task_obj.work_location_obj.name }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <template
                                v-if="taskWork && taskWork.task_obj && taskWork.task_obj.normative >= 0 && (taskWork.work_status === 'ASSIGNED' || taskWork.work_status === 'IN_PROGRESS')">
                                <div class="col-12 pt-3">
                                    Normativ: {{ taskWork.task_obj.normative }}
                                </div>
                                <div class="col-12 pt-1 pb-3">
                                    Minuta po jedinici: {{ taskWork.minutes_per_unit }}
                                </div>
                            </template>
                        </div>
                        <div class="row mt-3 mb-2">


                            <template v-if="['ASSIGNED', 'IN_PROGRESS'].includes(taskWork.work_status)">
                                <div class="btn-group d-flex flex-wrap" role="group">
                                    <div class="col-sm-4 col-6">
                                        <input type="radio" class="btn-check" name="btnradio" id="btnrA1" v-model="selectedAction" autocomplete="off"
                                            value="IN_PROGRESS">
                                        <label class="btn btn-outline-primary w-100 h-100" for="btnrA1">U TOKU</label>
                                    </div>
                                    <div class="col-sm-4 col-6">
                                        <input type="radio" class="btn-check" name="btnradio" v-model="selectedAction" id="btnrIP1"
                                            autocomplete="off" value="SUBMITTED">
                                        <label class="btn btn-outline-primary w-100 h-100" for="btnrIP1">ZAVRŠENO</label>
                                    </div>
                                    <div class="col-sm-4 col-6">
                                        <input type="radio" class="btn-check p-1" name="btnradio" v-model="selectedAction" id="btnrA3"
                                            autocomplete="off" value="NOT_STARTED">
                                        <label class="btn btn-outline-primary w-100 h-100" for="btnrA3">NIJE
                                            ZAPOČETO</label>
                                    </div>
                                </div>
                            </template>

                            <!-- Manager only -->
                            <!-- <template v-if="isManager">
                                <template v-if="taskWork && ['NOT_STARTED', 'SUBMITTED'].includes(taskWork.work_status)">
                                    <div class="btn-group" role="group">
                                        <div class="col-12 mt-2 mb-2">
                                            <input type="radio" class="btn-check" name="btnradio" id="btnrIP1"
                                                autocomplete="off" value="REVIEWED" v-model="selectedAction">
                                            <label class="btn btn-outline-primary w-100 h-100" for="btnrIP1">PREGLEDANO</label>
                                        </div>
                                    </div>
                                </template>
                            </template> -->

                            <!-- NOT MANAGER -->
                            <!-- <template v-else> -->
                            <template>
                                <template v-if="taskWork && ['NOT_STARTED', 'SUBMITTED'].includes(taskWork.work_status)">
                                    <div class="btn-group d-flex flex-wrap" role="group">
                                        <div class="col-sm-4 col-6">
                                            <input type="radio" disabled class="btn-check" name="btnradio" id="btnrA1" v-model="selectedAction" autocomplete="off"
                                                value="IN_PROGRESS">
                                            <label class="btn btn-outline-primary w-100 h-100" for="btnrA1">U TOKU</label>
                                        </div>
                                        <div class="col-sm-4 col-6">
                                            <input type="radio" disabled class="btn-check" name="btnradio" v-model="selectedAction" id="btnrIP1"
                                                autocomplete="off" value="SUBMITTED">
                                            <label class="btn btn-outline-primary w-100 h-100" for="btnrIP1">ZAVRŠENO</label>
                                        </div>
                                        <div class="col-sm-4 col-6">
                                            <input type="radio" disabled class="btn-check p-1" name="btnradio" v-model="selectedAction" id="btnrA3"
                                                autocomplete="off" value="NOT_STARTED">
                                            <label class="btn btn-outline-primary w-100 h-100" for="btnrA3">NIJE
                                                ZAPOČETO</label>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="row mt-2 mb-2">
                            <template v-if="taskWork.work_status === 'ASSIGNED' && selectedAction === 'IN_PROGRESS'">
                                    <div class="col-12">
                                        Posao će preći u stanje: U TOKU
                                    </div>
                            </template>
                            <template v-else-if="['IN_PROGRESS', 'ASSIGNED'].includes(taskWork.work_status)">
                                <template v-if="selectedAction === 'NOT_READY'">
                                    <div class="col-12 mb-3">
                                        Posao će preći u stanje: NIJE SPREMNO. Nakon potvrde, posao nije moguće ponovo započeti!
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="commentTextAreaNotReady">Komentar:</label>
                                            <textarea v-modal="comment" class="form-control" id="commentTextAreaNotReady" rows="5"></textarea>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="selectedAction === 'NOT_STARTED'">
    
                                    <div class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" v-model="notReady" id="flexCheckDefault">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Lokacija nije sprema za rad (Označite ako trenutno nije moguće obaviti posao)
                                            </label>
                                        </div>
                                    </div>
    
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="commentTextAreaNotStarted">Komentar:</label>
                                            <textarea v-modal="comment" class="form-control" id="commentTextAreaNotStarted" rows="5"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-12 mb-3">
                                        Posao će preći u stanje: NIJE ZAPOČETO. Nakon potvrde, posao nije moguće ponovo
                                        započeti!
                                    </div>
                                </template>
                                <template v-else-if="selectedAction === 'SUBMITTED'">
    
                                    <div class="col-9">
                                        <label for="workDoneInput">Urađeno posla:</label>
                                        <input type="number" class="form-control" v-model="finishedUnits" id="workDoneInput" />
                                    </div>
    
                                    <div class="col-3">
                                        <label for="workDoneInput">Jedinica:</label>
                                        <input type="string" disabled class="form-control" :value="taskWork.task_obj.work_position_obj.position_unit.norm_unit" id="workDoneInput" />
                                    </div>
                                    
                                    <div class="col-12 mt-3">
                                        <div class="form-group">
                                            <label for="commentTextAreaSumbitted">Komentar:</label>
                                            <textarea v-modal="comment" class="form-control" id="commentTextAreaSumbitted" rows="5"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-12 mt-3">
                                        Posao će preći u stanje: PREDATO. Nakon potvrde, posao nije moguće ponovo
                                        započeti!
                                    </div>
                                    
                                </template>
                            </template>

                            <!-- MANAGER -->
                            <template v-if="isManager">
                                <template v-if="taskWork && ['NOT_STARTED', 'SUBMITTED'].includes(taskWork.work_status)">

                                    <template v-if="selectedAction === 'NOT_STARTED'">
                                        <div class="col-12">
                                            <h5 v-if="notReady">Lokacija nije spremna za rad</h5>
                                            <h5 v-else>Radnik je predao posao (nije započeo sa radom)</h5>

                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="commentTextAreaNotStarted">Komentar:</label>
                                                <textarea disabled class="form-control" :value="taskWork.submission_comment" id="commentTextAreaNotStarted" rows="5"></textarea>
                                            </div>
                                        </div>


                                        <div class="btn-group" role="group">
                                            <div class="col-12 mt-2 mb-2">
                                                <input type="radio" class="btn-check" name="btnradio" id="btn-reviewed"
                                                    autocomplete="off" value="REVIEWED" v-model="selectedAction">
                                                <label class="btn btn-outline-primary w-100 h-100" for="btn-reviewed">PREGLEDANO</label>
                                            </div>
                                        </div>

                                    </template>
                                    <template v-else-if="selectedAction === 'SUBMITTED'">

                                        <div class="col-9">
                                            <label for="workDoneInput">Urađeno posla: (upisano je {{ taskWork.finished_units }})</label>
                                            <input type="number" class="form-control" v-model="finishedUnits" id="workDoneInput" />
                                        </div>

                                        <div class="col-3">
                                            <label for="workDoneInput">Jedinica:</label>
                                            <input type="string" disabled class="form-control" :value="taskWork.task_obj.work_position_obj.position_unit.norm_unit" id="workDoneInput" />
                                        </div>
                                        
                                        <div class="col-12 mt-3">
                                            <div class="form-group">
                                                <label for="commentTextAreaSumbitted">Komentar:</label>
                                                <textarea disabled :value="taskWork.submission_comment" class="form-control" id="commentTextAreaSumbitted" rows="5"></textarea>
                                            </div>
                                        </div>
                                        
                                        

                                        <div class="btn-group" role="group">
                                            <div class="col-6 mt-2 mb-2">
                                                <input type="radio" class="btn-check" name="radio-finished-type" id="btn-partial-finished"
                                                    autocomplete="off" :value="false" v-model="isFinished">
                                                <label class="btn btn-outline-primary w-100 h-100" for="btn-partial-finished">Parcijalno Završeno</label>
                                            </div>
                                            <div class="col-6 mt-2 mb-2">
                                                <input type="radio" class="btn-check" name="radio-finished-type" id="btn-all-finished"
                                                    autocomplete="off" :value="true" v-model="isFinished">
                                                <label class="btn btn-outline-primary w-100 h-100" for="btn-all-finished">Kompletno Završeno</label>
                                            </div>
                                        </div>

                                        
                                    </template>



                                </template>




                            </template>

                            <!-- NOT MANAGER -->
                            <template v-else>
                                <template v-if="taskWork && ['NOT_STARTED', 'SUBMITTED'].includes(taskWork.work_status)">
                                    

                                    <template v-if="selectedAction === 'NOT_STARTED'">
                                        <div class="col-12">
                                            <h5 v-if="notReady">Lokacija nije spremna za rad</h5>
                                            <h5 v-else>Radnik je predao posao (nije započeo sa radom)</h5>

                                        </div>

                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="commentTextAreaNotStarted">Komentar:</label>
                                                <textarea disabled class="form-control" :value="taskWork.submission_comment" id="commentTextAreaNotStarted" rows="5"></textarea>
                                            </div>
                                        </div>



                                    </template>
                                    <template v-else-if="selectedAction === 'SUBMITTED'">

                                        <div class="col-9">
                                            <label for="workDoneInput">Urađeno posla: </label>
                                            <input disabled type="number" class="form-control" v-model="finishedUnits" id="workDoneInput" />
                                        </div>

                                        <div class="col-3">
                                            <label for="workDoneInput">Jedinica:</label>
                                            <input type="string" disabled class="form-control" :value="taskWork.task_obj.work_position_obj.position_unit.norm_unit" id="workDoneInput" />
                                        </div>
                                        
                                        <div class="col-12 mt-3">
                                            <div class="form-group">
                                                <label for="commentTextAreaSumbitted">Komentar:</label>
                                                <textarea disabled :value="taskWork.submission_comment" class="form-control" id="commentTextAreaSumbitted" rows="5"></textarea>
                                            </div>
                                        </div>

                                        
                                    </template>


                                </template>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row w-100">
                        <div class="col-6">
                            <button type="button" class="btn btn-secondary w-100" @click="cancelModal()">Nazad</button>
                        </div>
                        <div class="col-6">
                            <button type="button" class="btn btn-primary w-100" @click="confirmModal()"
                                :disabled="disabledSave">Sačuvaj</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
import UtilService from '@/service/UtilService.js';
import JobAssignmentService from '@/service/JobAssignmentService.js';
import {mapState, mapGetters} from 'vuex'



export default {
    components: {},
    props: {
        taskWork: {
            type: Object,
            default: null
        }
    },

    watch: {
        'taskWork': {
            handler(val) {
                console.log(val);
                if (val) {
                    this.selectedAction = val.work_status
                    this.finishedUnits = val.finished_units
                }
            }
        },
    },

    data() {
        return {
            modal: null,
            selectedAction: this.taskWork?.work_status,
            notReady: false,
            status: {
                ASSIGNED: "DODELJEN",
                IN_PROGRESS: "U TOKU",
                SUBMITTED: "PREDATO",
                REVIEWED: "PREGLEDANO",
                NOT_STARTED: "NIJE POČELO"
            },
            finishedUnits: this.taskWork?.finishedUnits,
            isFinished: null,
            comment: "",
        }
    },

    computed: {
        ...mapState([
            'user',
        ]),

        ...mapGetters([
            'isManager',
        ]),

        disabledSave() {

            if (this.isManager && this.taskWork?.work_status === 'SUBMITTED' && this.isFinished == null) return true

            return this.selectedAction === null
        },
    },

    methods: {
        getLocationFullindex: UtilService.getLocationFullindex,
        getPositionFullindex: UtilService.getPositionFullindex,
        fullNameList: UtilService.fullNameList,

        async saveWork() {
            let status = this.selectedAction

            let data = {
                not_ready: status === "NOT_STARTED" ? this.notReady : false,
                finished_units: this.finishedUnits,
                is_finished: this.isFinished,
                comment: this.comment,
            }

            if (this.isManager && this.taskWork?.work_status === 'SUBMITTED') status = "REVIEWED"
            if (this.isManager && this.taskWork?.work_status === 'NOT_STARTED') {
                status = "REVIEWED"
                delete data.is_finished
            }

            data.status = status


            let payload = {
                url: {
                    jobAssignmentId: this.taskWork.jobAssignmentId,
                    workId: this.taskWork.id
                },
                data: data
            }
            try {
                let response = await JobAssignmentService.updateJobWork(payload);
                return response.data;
            } catch (error) {
                console.log(error)
                alert("Neuspesno")
            }

        },

        ///////

        async confirmModal() {
            await this.saveWork()

            this.hideModal()
            this.$emit('onConfirm', {action: this.selectedAction, taskWork: this.notReady, isReady: !this.notReady});
        },

        cancelModal() {
            this.hideModal()
            this.$emit('onCancel', null);
        },

        onModalShown() {
            if (this.taskWork) {
                this.selectedAction = this.taskWork?.work_status;
                this.finishedUnits = this.taskWork?.finished_units;
            }
        },

        showModal() {
            this.modal.show();
            this.onModalShown();

        },
        hideModal() {
            this.modal.hide();
        },

        setUpListener() {
            let myModalEl = this.$el
            this.modal = new Modal(myModalEl, { keyboard: false });
        },

        updateAction($event) {
            this.selectedAction = $event.target.value;
        }
    },

    mounted() {
        this.setUpListener();
    },

}
</script>

<style scoped>
.modal-content {
    height: calc(100vh - 3.5rem);
}

.modal-body {
    overflow: auto;
}
</style>
